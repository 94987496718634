<template>
  <div class="nation">
      <div class="box-jumpto-store">
        <span>门店代码</span>
        <el-select v-model="company_id" filterable ref="select"
          :clearable="true"
          @visible-change="onSelInput"
          @hook:mounted="onSelInput"
          placeholder="请输入门店代码或店名">
            <el-option v-for="item in companyOpts"
              :key="item.id"
              :value="item.id"
              :label="item.name">
            </el-option>
        </el-select>
        <a class="btn" @click="jumpToStore">去门店&gt;&gt;</a>
      </div>
      <div class="item" style="position: relative;">
        <div
          class="map-container"
          style="width: 8.5rem; height:7.907407rem; margin: auto;"
          ref="myEchart"
        ></div>
        <div class="map-label">
          <span><i style="background: #00C041;"></i>绿灯分值&gt;80分</span>
          <span><i style="background: #F3CA10;"></i>黄灯分值60-80分</span>
          <span><i style="background: #CF3329;"></i>红灯分值&lt;60分</span>
        </div>
        
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">得分公式</div>

        <div class="box-total" v-if="scoreData">
          <p>
          <strong class="a">{{scoreData.a}}</strong>
          <span class="a-p">展厅接待得分<i>(A)</i></span>
          </p>
          <p class="flag">=</p>
          <p>
          <strong>{{scoreData.b}}</strong>
          <span>系统使用得分 <i class="i-question" @click="popFormula=true"></i> <i>B ({{scoreData.b_percent}})</i></span>
          </p>
          <p class="flag">+</p>
          <p>
          <strong>{{scoreData.c}}</strong>
          <span>接待质量得分<i>C ({{scoreData.c_percent}})</i></span>
          </p>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="title">核心指标</div>
        <div class="box-core">
          <div class="main" v-for="(item, index) in entryData" :key="index" >
            <div class="value">
              <strong>{{ item.value }}</strong> {{['组','组','组','分'][index]}}
            </div>
            <div class="label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="item">
        <table class="tbl-nation" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th><span>排行</span></th>
            <th><span>展厅接待得分</span></th>
            <th><span>系统使用得分</span></th>
            <th><span>接待质量得分</span></th>
          </tr>
          <tr v-for="item in regionData" :key="item.id">
            <td @click="jumpToRegion(item)">{{ item.name }}</td>
            <td>{{ item.rank }}</td>
            <td><span :style="getBgColor(item.total)">{{ item.total }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.system)">{{ item.system }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.hall)">{{ item.hall }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="line"></div>
      <div class="item">
        <table class="tbl-nation" cellspacing="0" cellspadding="0">
          <tr>
            <th></th>
            <th><span>排行</span></th>
            <th><span>展厅接待得分</span></th>
            <th><span>系统使用得分</span></th>
            <th><span>接待质量得分</span></th>
          </tr>
          <tr v-for="item in areaData" :key="item.id">
            <td @click="jumpToArea(item)">{{ item.name }}</td>
            <td>{{ item.rank }}</td>
            <td><span :style="getBgColor(item.total)">{{ item.total }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.system)">{{ item.system }}</span>
            </td>
            <td>
              <span :style="getBgColor(item.hall)">{{ item.hall }}</span>
            </td>
          </tr>
        </table>
      </div>
      <el-dialog
        title=" "
        :visible.sync="popFormula"
        class="pop-formula" >
         <div class="box-formula">
           <p>{{formula.b_formula}}</p>
           <p>
             <span>人员使用得分=</span>
             <span class="division">
               <strong>{{formula.b1_numerator}}</strong>
               <strong>{{formula.b1_denominator}}</strong>
             </span>
             <span>{{formula.b1_right}}</span>
           </p>
           <p>
             <span>人员匹配得分=</span>
             <span class="division">
               <strong>{{formula.b2_numerator}}</strong>
               <strong>{{formula.b2_denominator}}</strong>
             </span>
             <span>{{formula.b2_right}}</span>
           </p>
         </div>
        <span slot="footer" class="dialog-footer">
        </span>
      </el-dialog>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import "../../../node_modules/echarts/map/js/china.js";
import nameMap from "@/utils/nameMap.js";
import { jump } from "@/utils/index";

export default {
  data() {
    return {
      entryData: [],
      regionData: [],
      areaData: [],
      scoreData: {},
      formula: {},
      popFormula: false,
      companyOpts: [],
      company_id: '',
    };
  },
  async mounted() {
    const { data: companyOpts } = await postData('/api/option/index', { table: "company", auth:1});
    this.companyOpts = (companyOpts || []).map(o => ({
      ...o,
      name: String(o.code).toUpperCase() + o.name,
    }));
    this.getDataBySearch();
  },
  methods: {
    onSelInput() {
      this.$nextTick(() => {
          const {select} = this.$refs;
          const input = select.$el.querySelector('.el-input__inner');
          input.removeAttribute('readonly');
      });
    },
    jumpToStore() {
      const query={...this.$store.state.queryDB,company_id:this.company_id};
      jump("/dashboard/store",this, query);
    },
    async getDataBySearch() {
      const params = { is_mobile: 1, ...this.$store.state.queryDB };
      this.loading = true;

      postData('/api/board/countrydayWeekMonth', params).then(res => {
        const {core } = res.data || {};
        this.entryData = [
          { label: "接待量", value: core.count },
          { label: "首次进店量", value: core.first },
          { label: "再次进店量", value: core.again },
          { label: "展厅接待得分", value: core.total },
        ];
      });
      postData('/api/board/country_table', params).then(res => {
        const {a, b, c, d, b_percent, c_percent, d_percent, formula} = res.data || {};
        this.formula = formula;
        this.scoreData = res.data;
        this.scorePercent = { b_percent, c_percent, d_percent };
      });
      postData('/api/board/country_regions', params).then(res => {
        const {region} = res.data || [];
        this.regionData = region.sort((a, b) => a.rank - b.rank);
      });
      postData('/api/board/country_area', params).then(res => {
        const {area} = res.data || [];
        this.areaData = area;
      });
      postData('/api/board/country_province', params).then(res => {
        this.loading = false;
        const {province} = res.data || [];
        this.province = province.map((o) => ({
          name: o.name,
          value: [o.lng, o.lat],
          num: o.total,
          itemStyle: { color: this.getColor(o.total) },
        }));
        this.initEcharts(this.province);
      });
      
    },

    initEcharts(dataValue) {
      this.myEchart = echarts.init(this.$refs.myEchart);

      const option = {
        tooltip: {
          show: true,
        },
        geo: {
          map: "china",
          roam: false, // 一定要关闭拖拽
          zoom: 1.23,
          center: [105, 36], // 调整地图位置
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "#fff",
            },
            emphasis: {
              show: true,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#0462fd",
              borderColor: "#0462fd",
              borderWidth: 1, //设置外层边框
              shadowBlur: 2,
              //shadowOffsetY: 2,
              //shadowOffsetX: 0,
              //shadowColor: "#0462fd",
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            roam: false,
            zoom: 1.23,
            center: [105, 36],
            // geoIndex: 1,
            // aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true,
                color: "#000",
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#fff",
                borderColor: "#000",
                borderWidth: 0.5,
              },
              emphasis: {
                areaColor: "#ccc",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 5,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: dataValue,
            symbol: "circle",
            symbolSize: 8,
            hoverSymbolSize: 8,
            tooltip: {
              formatter(value) {
                return value.data.name + "<br/>" + value.data.num;
              },
              show: true,
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
              period: 6,
              scale: 2,
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              shadowBlur: 2,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
        ],
      };

      this.myEchart.setOption(option);
    },
    getColor(v) {
      if (v < 60) {
        return "#CF3329";
      }
      if (v >= 80) {
        return "#00C041";
      }
      return "#F3CA10";
    },
    getBgColor(v, mode = null) {
      const { ft } = getLightColor(v);
      return `color:${ft}`;
    },
    jumpToRegion(item) {
      const query={...this.$store.state.queryDB,region_id:item.id};
      jump("/dashboard/region",this, query);
  
    },
    jumpToArea(item) {
      const query={...this.$store.state.queryDB,area_id:item.id};
      jump("/dashboard/area",this, query);
    
    },
  },
};

function getLightColor(v) {
    if (v < 60 || v === '-') {
        return {
            ft: '#ED5A54',
            bg: '#5b263d',
        };
    }
    if (v >= 80) {
        return {
            ft: '#11C16F',
            bg: '#0b701f',
        };
    }
    return {
        ft: '#FAC15C',
        bg: '#a79500',
    };
}
</script>
<style lang="scss" scoped>
@import "@/assets/index.scss";
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nation {
}
.tbl-nation{
  width: 100%;
  color: #4F5154;
  background: #fff;
  text-align: center;
  tr{
    display: flex;
  }
  td {
    font-size: 0.32rem;
  }
  th {
    font-size: 0.26666rem;
    span {
      display: block;
      transform: scale(0.9);
    }
  }
  td, th {
    flex: 1;
    border: 0;
    padding: 0.34666rem 0;
    font-weight: bold;

    &:first-child {
      background: #EDEEEE;
      text-align: left;
      padding-left: 0.26666rem;
    }
    &:nth-child(2) {
      max-width: 1.2rem;
    }
  }
}

.map-label {
  margin-top: 0.26666rem;
  z-index: 100;
  left: 0;
  width: 100%;
  text-align: center;
  color: #646972;
  display: flex;
  justify-content: center;
  line-height: 2;
  span {
    display: inline-block;
    margin: 0 0.32rem;
  }
  i {
    width: 0.32rem;
    height: 0.32rem;
    display: block;
    border-radius: 50%;
    margin: auto;
  }
}

</style>


